<template>
  <div>
    <!-- 顶部菜单 -->
    <a-menu
      theme="dark"
      mode="horizontal"
      :defaultSelectedKeys="selectedKeys"
      :style="{ lineHeight: '64px' }"
    >
      <template v-for="menuInfo in menuList">
        <template v-if="!menuInfo.meta.hideInMenu">
          <a-menu-item :key="menuInfo.path" @click="menuClick(menuInfo)">
            {{ menuInfo.meta.title }}
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  name: "topSider",
  data() {
    return {
      // 设置默认选中的 key
      selectedKeys: [],
    };
  },
  computed: {
    ...mapGetters({
      menuList: "app/menuList",
    }),
  },
  watch: {
    // 监听 Vuex 状态变化，确保 selectedKeys 更新
    selectedKeysFromStore(newVal) {
      this.selectedKeys = newVal;
    },
  },
  methods: {
    menuClick(e) {
      this.$router.push(e.path);
      this.$emit("menuClick", e);
      this.$store.commit("community/updatemeunMsg", e);
    },
    // createdMenu() {
    //   this.$store.commit("community/updatemeunMsg", {});
    //   // 页面加载侧边栏显示第一个地址
    //   if (this.menuList.length) {
    //     this.menuList.forEach((item) => {
    //       if (
    //         item.path ===
    //         "views-communityGovernance-basicOrganization-personnelManage-residentFile-index"
    //       ) {
    //         this.$store.commit("community/updatemeunMsg", e);
    //       }
    //     });
    //   }
    // },
  },
  created() {
    if (this.$store.state.community.meunMsg.path) {
      this.selectedKeys = [this.$store.state.community.meunMsg.path];
      // this.createdMenu();
    } else {
      this.selectedKeys = [
        "/views/communityGovernance/basicOrganization/personnelManage/residentFile/index",
      ];
    }
  },
};
</script>
  
  <style lang="less" scoped>
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 30px !important;
  font-size: 18px !important;
}
// 顶部菜单样式
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #2a5cff;
}
// 选中样式
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #204fea;
}
</style>
  